import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { db } from './Firebase';
import { getTaiwanTimeString } from './TaiwanTimeStamp';
import MedicineSearch from './MedicineSearch';
import Uploader from './Uploader';
import ICDCodeSelector from './ICDCodeSelector';

const ConsultationForm = forwardRef(({
  selectedPatient,
  selectedDoctor,
  currentRecord,
  onRecordUpdate,
  targetClinicId
}, ref) => {
  // Initialize form state with empty values
  const [formData, setFormData] = useState({
    complains: '',
    diagnosis: '',
    medications: [],
    patientImages: [],
    diagnosisCodes: [],
    diagnosisDetails: {},
    temperature: '',
    respirationRate: '',
    bloodPressure: ''
  });

  const [isFormDirty, setIsFormDirty] = useState(false);

  // Reset form when patient changes
  useEffect(() => {
    if (!selectedPatient) {
      resetForm();
      return;
    }

    // Load current record data if available
    if (currentRecord) {
      setFormData({
        complains: currentRecord.complains || '',
        diagnosis: currentRecord.diagnosis || '',
        medications: currentRecord.medications || [],
        patientImages: currentRecord.imageUrls || [],
        diagnosisCodes: currentRecord.diagnosisCodes || [],
        diagnosisDetails: currentRecord.diagnosisDetails || {},
        temperature: currentRecord.temperature || '',
        respirationRate: currentRecord.respirationRate || '',
        bloodPressure: currentRecord.bloodPressure || ''
      });
      setIsFormDirty(false);
    } else {
      resetForm();
    }
  }, [selectedPatient, currentRecord]);

  const resetForm = () => {
    setFormData({
      complains: '',
      diagnosis: '',
      medications: [],
      patientImages: [],
      diagnosisCodes: [],
      diagnosisDetails: {},
      temperature: '',
      respirationRate: '',
      bloodPressure: ''
    });
    setIsFormDirty(false);
  };

  const handleFormChange = (field, value) => {
    const newFormData = {
      ...formData,
      [field]: value
    };
    setFormData(newFormData);
    setIsFormDirty(true);

    // Only notify parent if there's a current record
    if (currentRecord) {
      onRecordUpdate?.({
        ...currentRecord,
        [field]: value,
        lastUpdated: getTaiwanTimeString()
      });
    }
  };

  const saveCurrentRecord = async () => {
    if (!selectedPatient?.medicalRecordId) {
      toast.error('無法保存：找不到病歷ID');
      return false;
    }

    try {
      // Prepare record data with all fields
      const recordData = {
        patientId: selectedPatient.patientId,
        patientName: selectedPatient.userName,
        patientTwId: selectedPatient.userTWId,
        clinicId: targetClinicId,
        doctorId: selectedDoctor.doctorId,
        doctorName: selectedDoctor.doctorName,
        complains: formData.complains,
        diagnosis: formData.diagnosis,
        medications: formData.medications,
        imageUrls: formData.patientImages,
        diagnosisCodes: formData.diagnosisCodes,
        diagnosisDetails: formData.diagnosisDetails,
        temperature: formData.temperature,
        respirationRate: formData.respirationRate,
        bloodPressure: formData.bloodPressure,
        status: currentRecord?.status || '進行中',
        lastUpdated: getTaiwanTimeString(),
        createdAt: currentRecord?.createdAt || getTaiwanTimeString()
      };

      // Save to Firestore
      const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
      await setDoc(recordRef, recordData, { merge: true });

      setIsFormDirty(false);
      onRecordUpdate?.(recordData);
      toast.success('病歷已更新');
      return true;

    } catch (error) {
      console.error('Error saving record:', error);
      toast.error('儲存失敗');
      return false;
    }
  };

  const completeConsultation = async () => {
    if (!selectedPatient?.medicalRecordId) {
      toast.error('無法完成：找不到病歷ID');
      return;
    }

    try {
      // First save current record
      const saved = await saveCurrentRecord();
      if (!saved) return;

      // Update medical record status
      const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
      await setDoc(recordRef, {
        status: '完成',
        completedAt: getTaiwanTimeString()
      }, { merge: true });

      // Update appointment status if exists
      if (selectedPatient.id) {
        const appointmentRef = doc(db, 'Appointment', selectedPatient.id);
        await setDoc(appointmentRef, {
          status: '已看診',
          lastUpdated: getTaiwanTimeString()
        }, { merge: true });
      }

      toast.success('已完成看診');
      resetForm();
    } catch (error) {
      console.error('Error completing consultation:', error);
      toast.error('完成看診失敗');
    }
  };

  // Handler for patient switching
  const handlePatientSwitch = async () => {
    if (isFormDirty) {
      const confirmed = window.confirm('您有未儲存的變更，是否要儲存？');
      if (confirmed) {
        return await saveCurrentRecord();
      }
    }
    resetForm();
    return true;
  };

  // Handle image operations
  const handleImageUpload = async (imageUrl) => {
    const newImages = [...formData.patientImages, imageUrl];
    handleFormChange('patientImages', newImages);
  };

  const handleImageDelete = (urlToDelete) => {
    const newImages = formData.patientImages.filter(url => url !== urlToDelete);
    handleFormChange('patientImages', newImages);
  };

  // Expose methods to parent
  useImperativeHandle(ref, () => ({
    isFormDirty: () => isFormDirty,
    saveCurrentRecord,
    completeConsultation,
    handlePatientSwitch,
    resetForm,
    updateFormData: (data) => {
      setFormData({
        complains: data.complains || '',
        diagnosis: data.diagnosis || '',
        medications: data.medications || [],
        patientImages: data.imageUrls || [],
        diagnosisCodes: data.diagnosisCodes || [],
        diagnosisDetails: data.diagnosisDetails || {},
        temperature: data.temperature || '',
        respirationRate: data.respirationRate || '',
        bloodPressure: data.bloodPressure || ''
      });
      setIsFormDirty(true);

      // Also notify parent of the update with complete data
      if (currentRecord) {
        onRecordUpdate?.({
          ...currentRecord,
          ...data,
          lastUpdated: getTaiwanTimeString()
        });
      }
    }
  }));

  if (!selectedPatient) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500 text-lg">請選擇病患以開始看診</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Patient Info Header */}
      <div className="grid grid-cols-4 gap-4 bg-gray-50 p-4 rounded-lg">
        <div>
          <p className="text-sm text-gray-500">姓名</p>
          <p className="font-medium">{selectedPatient.userName || '未知'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">身分證</p>
          <p className="font-medium">{selectedPatient.userTWId || '未知'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">生日</p>
          <p className="font-medium">{selectedPatient.birthday || '未知'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">年齡</p>
          <p className="font-medium">
            {selectedPatient.birthday
              ? moment().diff(moment(selectedPatient.birthday), 'years')
              : '未知'}
          </p>
        </div>
      </div>

      {/* Main Form Content */}
      <div className="space-y-6">
        {/* Chief Complaint */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            主訴
          </label>
          <textarea
            value={formData.complains}
            onChange={(e) => handleFormChange('complains', e.target.value)}
            rows={3}
            className="w-full p-2 border border-gray-300 rounded-lg"
            placeholder="病患主訴..."
          />
        </div>

        {/* Vital Signs */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              體溫 (°C)
            </label>
            <input
              type="text"
              value={formData.temperature}
              onChange={(e) => handleFormChange('temperature', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="36.5"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              呼吸次數 (次/分)
            </label>
            <input
              type="text"
              value={formData.respirationRate}
              onChange={(e) => handleFormChange('respirationRate', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="16"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              血壓 (mmHg)
            </label>
            <input
              type="text"
              value={formData.bloodPressure}
              onChange={(e) => handleFormChange('bloodPressure', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="120/80"
            />
          </div>
        </div>

        {/* Diagnosis Codes */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            診斷碼
          </label>
          <ICDCodeSelector
            onSelect={(selectedCode) => {
              if (!formData.diagnosisCodes.includes(selectedCode.id)) {
                // Update both codes and details atomically
                const newFormData = {
                  ...formData,
                  diagnosisCodes: [...formData.diagnosisCodes, selectedCode.id],
                  diagnosisDetails: {
                    ...formData.diagnosisDetails,
                    [selectedCode.id]: {
                      englishName: selectedCode.englishName,
                      chineseName: selectedCode.chineseName
                    }
                  }
                };
                setFormData(newFormData);
                setIsFormDirty(true);

                if (currentRecord) {
                  onRecordUpdate?.({
                    ...currentRecord,
                    diagnosisCodes: newFormData.diagnosisCodes,
                    diagnosisDetails: newFormData.diagnosisDetails,
                    lastUpdated: getTaiwanTimeString()
                  });
                }
              }
            }}
            clinicId={targetClinicId}
            doctorId={selectedDoctor.doctorId}
          />

          {/* Display selected codes */}
          <div className="mt-2 flex flex-wrap gap-2">
            {formData.diagnosisCodes.map((code) => (
              <div
                key={code}
                className="group inline-flex items-center bg-gray-100 hover:bg-gray-200 
                         rounded-lg px-3 py-1.5 text-sm transition-colors"
              >
                <div className="flex flex-col">
                  <span className="font-medium">{code}</span>
                  {formData.diagnosisDetails[code]?.chineseName && (
                    <span className="text-xs text-gray-600">
                      {formData.diagnosisDetails[code].englishName} |
                      {formData.diagnosisDetails[code].chineseName}
                    </span>
                  )}
                </div>
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      // 1. 過濾掉要刪除的代碼
                      const newCodes = formData.diagnosisCodes.filter(c => c !== code);

                      // 2. 創建全新的 details 對象，只包含剩餘的代碼
                      const newDetails = {};
                      newCodes.forEach(remainingCode => {
                        if (formData.diagnosisDetails[remainingCode]) {
                          newDetails[remainingCode] = formData.diagnosisDetails[remainingCode];
                        }
                      });

                      // 3. 準備更新的數據
                      const updatedData = {
                        diagnosisCodes: newCodes,
                        diagnosisDetails: newDetails,
                        lastUpdated: getTaiwanTimeString()
                      };

                      // 4. 首先更新 Firestore
                      if (selectedPatient?.medicalRecordId) {
                        const recordRef = doc(db, 'MedicalRecords', selectedPatient.medicalRecordId);
                        await setDoc(recordRef, {
                          ...currentRecord,
                          ...updatedData
                        }, { merge: false }); // 使用 merge: false 以完全替換文檔
                      }

                      // 5. 更新本地狀態
                      setFormData(prev => ({
                        ...prev,
                        ...updatedData
                      }));
                      setIsFormDirty(true);

                      // 6. 通知父組件
                      if (currentRecord) {
                        onRecordUpdate?.({
                          ...currentRecord,
                          ...updatedData
                        });
                      }

                      toast.success('診斷碼已更新');
                    } catch (error) {
                      console.error('Error updating diagnosis codes:', error);
                      toast.error('更新診斷碼失敗');
                    }
                  }}
                  className="ml-2 text-gray-400 hover:text-red-500 transition-colors"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>


        {/* Medications */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            藥令
          </label>
          <MedicineSearch
            medications={formData.medications}
            onChange={(newMedications) => handleFormChange('medications', newMedications)}
          />
        </div>

        {/* Image Upload */}
        <div>
          <label className="block text-l font-medium text-gray-700 mb-2">
            上傳圖片
          </label>
          <Uploader
            images={formData.patientImages}
            onUpload={handleImageUpload}
            onDelete={handleImageDelete}
          />
        </div>
      </div>

      {/* Form Actions */}
      <div className="sticky bottom-0 bg-white pt-4 pb-2 border-t border-gray-200 mt-6">
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={saveCurrentRecord}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 shadow-lg transition-all duration-200 hover:shadow-xl"
          >
            儲存病歷
          </button>
          <button
            type="button"
            onClick={completeConsultation}
            className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 shadow-lg transition-all duration-200 hover:shadow-xl"
          >
            完成看診
          </button>
        </div>
      </div>

    </div>
  );
});

export default ConsultationForm;