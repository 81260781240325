import React, { useState } from 'react';
import Modal from './Modal';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { FiCopy, FiX } from 'react-icons/fi';

const MedicalHistoryDetailModal = ({ isOpen, onClose, record, onCopy }) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const handleCopy = () => {
    if (!record) return;
    onCopy(record);
    toast.success('已複製病歷內容');
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case '完成':
        return 'bg-green-100 text-green-800';
      case '進行中':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (!record) return null;

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={onClose}
        title="病歷詳細內容"
        width="max-w-4xl"
      >
        <div className="space-y-6">
          {/* Header Information */}
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-xl font-semibold">{record.patientName}</h2>
              <p className="text-sm text-gray-500">
                {moment(record.lastUpdated).format('YYYY-MM-DD HH:mm')}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className={`px-3 py-1 rounded-full text-sm ${getStatusBadgeColor(record.status)}`}>
                {record.status}
              </span>
              <button
                onClick={handleCopy}
                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                <FiCopy className="w-4 h-4" />
                複製到當前病歷
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="grid grid-cols-1 gap-6">
            {/* Vital Signs */}
            <div className="grid grid-cols-3 gap-4 bg-gray-50 p-1 rounded-lg">
              <div>
                <h3 className="text-xs font-medium text-gray-600">體溫</h3>
                <p className="text-xs">{record.temperature || '--'} °C</p>
              </div>
              <div>
                <h3 className="text-xs font-medium text-gray-600">呼吸次數</h3>
                <p className="text-xs">{record.respirationRate || '--'} 次/分</p>
              </div>
              <div>
                <h3 className="text-xs font-medium text-gray-600">血壓</h3>
                <p className="text-xs">{record.bloodPressure || '--'} mmHg</p>
              </div>
            </div>

            {/* Chief Complaint */}
            <div>
              <h3 className="text-lg font-medium mb-2">主訴</h3>
              <div className="p-3 bg-gray-50 rounded-lg">
                {record.complains || '無主訴記錄'}
              </div>
            </div>

            {/* Diagnosis Codes */}
            {/* Diagnosis Codes */}
            <div>
              <h3 className="text-lg font-medium mb-2">診斷碼</h3>
              <div className="p-3 bg-gray-50 rounded-lg">
                {record.diagnosisCodes?.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {record.diagnosisCodes.map((code, index) => {
                      const details = record.diagnosisDetails?.[code] || {};
                      return (
                        <div
                          key={index}
                          className="inline-flex flex-col text-sm bg-blue-100 text-blue-800 px-3 py-2 rounded"
                        >
                          <span className="font-medium">{code}</span>
                          {details.englishName && (
                            <span className="text-xs text-blue-600">
                              {details.englishName}
                            </span>
                          )}
                          {details.chineseName && (
                            <span className="text-xs text-gray-600">
                              {details.chineseName}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  '無診斷碼記錄'
                )}
              </div>
            </div>

            {/* Medications Table */}
            <div>
              <h3 className="text-lg font-medium mb-2">用藥</h3>
              {record.medications?.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 bg-gray-50 rounded-lg">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">藥品名稱</th>
                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">劑量</th>
                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">頻率</th>
                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">天數</th>
                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">總量</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {record.medications.map((med, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-4 py-2 text-sm">{med.name}</td>
                          <td className="px-4 py-2 text-sm">{med.dosage}</td>
                          <td className="px-4 py-2 text-sm">{med.frequency}</td>
                          <td className="px-4 py-2 text-sm">{med.duration}</td>
                          <td className="px-4 py-2 text-sm">{med.total} 單位</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="p-3 bg-gray-50 rounded-lg">無用藥記錄</div>
              )}
            </div>
          </div>

          {/* Images Section */}
          {record.imageUrls?.length > 0 && (
            <div>
              <h3 className="text-lg font-medium mb-2">影像記錄</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {record.imageUrls.map((url, index) => (
                  <div
                    key={index}
                    className="relative group cursor-pointer"
                    onClick={() => handleImageClick(url)}
                  >
                    <img
                      src={url}
                      alt={`Medical record ${index + 1}`}
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity rounded-lg" />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Additional Information */}
          <div className="text-sm text-gray-500 mt-4">
            <p>醫師：{record.doctorName}</p>
            <p>建立時間：{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</p>
            {record.lastUpdated !== record.createdAt && (
              <p>最後更新：{moment(record.lastUpdated).format('YYYY-MM-DD HH:mm')}</p>
            )}
          </div>
        </div>
      </Modal>

      {/* Image Preview Modal */}
      {isImageModalOpen && selectedImage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative max-w-4xl mx-auto">
            <button
              onClick={() => setIsImageModalOpen(false)}
              className="absolute top-4 right-4 text-white hover:text-gray-300"
            >
              <FiX className="w-6 h-6" />
            </button>
            <img
              src={selectedImage}
              alt="Full size preview"
              className="max-h-[90vh] max-w-full object-contain"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MedicalHistoryDetailModal;