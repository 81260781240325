import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './Firebase';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { FiRefreshCw, FiSearch, FiX } from 'react-icons/fi';
import { toast } from 'react-hot-toast';

const ICDCodeSelector = ({ onSelect, userId, clinicId, doctorId }) => {
  // State declarations
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [frequentCodes, setFrequentCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dbCount, setDbCount] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [showFrequentCodes, setShowFrequentCodes] = useState(false);

  // Refs
  const fuseRef = useRef(null);
  const localDBRef = useRef(null);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const selectedItemRef = useRef(null);

    // Add scroll detection
    useEffect(() => {
      if (suggestionsRef.current) {
        setIsScrollable(
          suggestionsRef.current.scrollHeight > suggestionsRef.current.clientHeight
        );
      }
    }, [suggestions]);
  
    // Scroll selected item into view
    useEffect(() => {
      if (selectedItemRef.current && suggestionsRef.current) {
        selectedItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }, [selectedIndex]);

  // Initialize IndexedDB
  useEffect(() => {
    const request = indexedDB.open('ICDCodesDB', 1);

    request.onerror = () => {
      console.error("IndexedDB error");
      toast.error("無法初始化本地資料庫");
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('icdCodes')) {
        db.createObjectStore('icdCodes', { keyPath: 'id' });
      }
    };

    request.onsuccess = (event) => {
      localDBRef.current = event.target.result;
      loadICDCodes();
    };
  }, []);

  // Load frequent codes on mount
  useEffect(() => {
    loadFrequentCodesFromFirebase();
  }, [clinicId, doctorId]);

  // Initialize Fuse.js for fuzzy search
  const initializeFuse = (codes) => {
    fuseRef.current = new Fuse(codes, {
      keys: ['id', 'englishName', 'chineseName'],
      threshold: 0.3,
      includeScore: true
    });
  };

  // Load ICD codes from IndexedDB
  const loadICDCodes = async () => {
    try {
      const store = localDBRef.current
        .transaction('icdCodes', 'readonly')
        .objectStore('icdCodes');
      
      const countRequest = store.count();
      
      countRequest.onsuccess = async () => {
        const currentCount = countRequest.result;
        setDbCount(currentCount);
        
        if (currentCount === 0) {
          await fetchAndStoreICDCodes();
        } else {
          const getAllRequest = store.getAll();
          getAllRequest.onsuccess = () => {
            initializeFuse(getAllRequest.result);
          };
        }
      };

      setIsLoading(false);
    } catch (error) {
      console.error('Error loading ICD codes:', error);
      toast.error('載入 ICD 代碼失敗');
      setIsLoading(false);
    }
  };

  // Fetch and store ICD codes
  const fetchAndStoreICDCodes = async () => {
    try {
      setIsLoading(true);
      const icdQuery = query(collection(db, 'ICD10PCS'));
      const snapshot = await getDocs(icdQuery);
      const codes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      await storeICDCodes(codes);
      initializeFuse(codes);
      setDbCount(codes.length);
      
    } catch (error) {
      console.error('Error fetching ICD codes:', error);
      toast.error('獲取 ICD 代碼失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // Store ICD codes in batches
  const storeICDCodes = async (codes) => {
    try {
      const BATCH_SIZE = 100;
      const transaction = localDBRef.current.transaction('icdCodes', 'readwrite');
      const store = transaction.objectStore('icdCodes');

      // Clear existing data
      await store.clear();

      // Process in batches
      for (let i = 0; i < codes.length; i += BATCH_SIZE) {
        const batch = codes.slice(i, i + BATCH_SIZE);
        for (const code of batch) {
          store.put(code);
        }
      }

      return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
      });
    } catch (error) {
      console.error('Error storing ICD codes:', error);
      throw error;
    }
  };

  // Load frequent codes from Firebase
  const loadFrequentCodesFromFirebase = async () => {
    if (!clinicId || !doctorId) return;

    try {
      const clinicRef = doc(db, 'OwnedClinic', clinicId);
      const clinicDoc = await getDoc(clinicRef);

      if (clinicDoc.exists()) {
        const clinicData = clinicDoc.data();
        const doctors = clinicData.doctors || [];
        const currentDoctor = doctors.find(d => d.doctorId === doctorId);

        if (currentDoctor?.frequentICDCodes) {
          setFrequentCodes(currentDoctor.frequentICDCodes);
        } else {
          // Try loading from local storage as fallback
          const localCodes = localStorage.getItem(`frequentICDCodes_${doctorId}`);
          if (localCodes) {
            setFrequentCodes(JSON.parse(localCodes));
          }
        }
      }
    } catch (error) {
      console.error('Error loading frequent codes:', error);
      // Fallback to local storage
      const localCodes = localStorage.getItem(`frequentICDCodes_${doctorId}`);
      if (localCodes) {
        setFrequentCodes(JSON.parse(localCodes));
      }
    }
  };

  // Update frequent codes
  const updateFrequentCodes = async (selectedCode) => {
    try {
      const newFrequentCodes = [selectedCode, ...frequentCodes]
        .filter((code, index, self) => 
          index === self.findIndex(c => c.id === code.id)
        )
        .slice(0, 20);

      setFrequentCodes(newFrequentCodes);
      
      // Update local storage
      localStorage.setItem(
        `frequentICDCodes_${doctorId}`,
        JSON.stringify(newFrequentCodes)
      );
      
      // Update Firebase if possible
      if (clinicId && doctorId) {
        const clinicRef = doc(db, 'OwnedClinic', clinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctors = clinicData.doctors || [];
          const doctorIndex = doctors.findIndex(d => d.doctorId === doctorId);

          if (doctorIndex !== -1) {
            doctors[doctorIndex] = {
              ...doctors[doctorIndex],
              frequentICDCodes: newFrequentCodes
            };

            await updateDoc(clinicRef, { doctors });
          }
        }
      }
    } catch (error) {
      console.error('Error updating frequent codes:', error);
      toast.error('更新常用代碼失敗');
    }
  };

  // Reload ICD codes
  const reloadICDCodes = async () => {
    try {
      setIsRefreshing(true);
      await fetchAndStoreICDCodes();
      toast.success(`已更新 ${dbCount} 筆 ICD 代碼`);
    } catch (error) {
      console.error('Error reloading ICD codes:', error);
      toast.error('更新 ICD 代碼失敗');
    } finally {
      setIsRefreshing(false);
    }
  };

  // Handle code selection
  const handleSelect = async (code) => {
    if (!code) return;

    try {
      onSelect?.(code);
      setSelectedCodes(prev => [...prev, code]);
      await updateFrequentCodes(code);
      
      setSearchTerm('');
      setSuggestions([]);
      setShowSuggestions(false);
      setSelectedIndex(0);
      
      toast.success(`已選擇: ${code.id} - ${code.chineseName}`);
    } catch (error) {
      console.error('Error in handleSelect:', error);
      toast.error('選擇代碼時發生錯誤');
    }
  };

  // Remove selected code
  const handleRemoveCode = (codeToRemove) => {
    setSelectedCodes(prev => prev.filter(code => code.id !== codeToRemove.id));
  };

  const handleCloseSuggestions = () => {
    setShowSuggestions(false);
    setShowFrequentCodes(false);
    setSearchTerm('');
    setSuggestions([]);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (suggestions[selectedIndex]) {
          handleSelect(suggestions[selectedIndex]);
        }
        break;
      default:
        break;
    }
  };

  // Handle input focus
  const handleFocus = () => {
    if (!searchTerm && frequentCodes.length > 0) {
      setShowFrequentCodes(true);
      setSuggestions(frequentCodes.slice(0, 10));
    }
    setShowSuggestions(true);
  };

  // Debounced search function
  const debouncedSearch = debounce((term) => {
    if (!term) {
      setSuggestions(frequentCodes.slice(0, 10));
      return;
    }

    if (fuseRef.current) {
      const results = fuseRef.current.search(term)
        .slice(0, 10)
        .map(result => result.item);
      setSuggestions(results);
      setSelectedIndex(0);
    }
  }, 150);

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };


  return (
    <div className="relative w-full">
      {/* Search input and controls */}
      <div className="mb-4 flex items-center gap-2">
        <div className="relative flex-1">
          <div className="relative">
            <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder="搜尋 ICD 代碼或名稱..."
              className="w-full pl-10 pr-20 py-2 border border-gray-300 rounded-lg"
            />
            {(showSuggestions || showFrequentCodes) && (
              <button
                onClick={handleCloseSuggestions}
                className="absolute right-12 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <FiX className="w-4 h-4" />
              </button>
            )}
            <div className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 text-sm">
              {dbCount > 0 && `${dbCount} 筆`}
            </div>
          </div>
        </div>
        <button
          onClick={reloadICDCodes}
          disabled={isRefreshing}
          className={`p-2 rounded-lg border border-gray-300 hover:bg-gray-50 
                     ${isRefreshing ? 'opacity-50 cursor-not-allowed' : ''}`}
          title="重新載入 ICD 代碼"
        >
          <FiRefreshCw className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} />
        </button>
      </div>

      {/* Search suggestions with scroll indicator */}
      {(showSuggestions && suggestions.length > 0) && (
        <div className="relative">
          {/* Close button for suggestions */}
          <button
            onClick={() => setShowSuggestions(false)}
            className="absolute -top-2 -right-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
            title="關閉建議"
          >
            <FiX className="w-4 h-4 text-gray-600" />
          </button>
          <div 
            ref={suggestionsRef}
            className="absolute z-40 w-full mt-1 bg-white border border-gray-200 
                     rounded-lg shadow-lg max-h-60 overflow-y-auto 
                     scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
          >
            {showFrequentCodes && (
              <div className="px-4 py-2 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                <span className="text-sm font-medium text-gray-600">最近使用的代碼</span>
                <button
                  onClick={() => setShowFrequentCodes(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <FiX className="w-4 h-4" />
                </button>
              </div>
            )}
            
            {suggestions.map((code, index) => (
              <div
                key={code.id}
                ref={index === selectedIndex ? selectedItemRef : null}
                onClick={() => handleSelect(code)}
                className={`w-full px-4 py-2 text-left cursor-pointer
                         hover:bg-gray-50 flex flex-col border-b border-gray-100
                         ${index === selectedIndex ? 'bg-blue-50' : ''}`}
              >
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-blue-600">{code.id}</span>
                  <span className="text-sm text-gray-500">|</span>
                  <span className="font-medium">{code.chineseName}</span>
                </div>
                <span className="text-sm text-gray-600">{code.englishName}</span>
              </div>
            ))}
          </div>
          
          {/* Scroll indicator */}
          {isScrollable && (
            <div className="absolute right-0 top-1 bottom-1 w-1 z-50">
              <div className="h-full w-full bg-gray-200 rounded-r-lg">
                <div className="w-full bg-gray-400 rounded-r-lg opacity-50 
                              transition-all duration-300
                              hover:opacity-75 hover:w-1.5"
                     style={{
                       height: '30%',
                       transform: `translateY(${(selectedIndex / suggestions.length) * 70}%)`
                     }}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Loading indicator */}
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}

      {/* Keyboard navigation hint */}
      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute right-0 -bottom-6 text-xs text-gray-500 flex items-center gap-1">
          <span>使用 ↑↓ 鍵選擇</span>
          <span>或滾動滑鼠滾輪瀏覽</span>
        </div>
      )}
    </div>
  );
};

export default ICDCodeSelector;