import React, { useState } from 'react';

const MedicineSearch = ({ medications = [], onChange }) => {
  const [manualInput, setManualInput] = useState('');

  // 預設的劑量、頻率和持續時間選項
  const dosageOptions = ['1顆', '2顆', '3顆', '半顆', '1包', '2包', '5ml', '10ml'];
  const frequencyOptions = ['每天1次', '每天2次', '每天3次', '每4小時1次', '每6小時1次', '睡前服用'];
  const durationOptions = ['1天', '3天', '5天', '7天', '10天', '14天', '28天'];

  const handleManualInputChange = (e) => {
    setManualInput(e.target.value);
  };

  const handleAddManualMedication = () => {
    if (manualInput.trim() === '') return;

    const newMedication = {
      id: Date.now().toString(),
      name: manualInput.trim(),
      dosage: '1顆',
      frequency: '每天3次',
      duration: '3天',
      total: '9',  // 預計算好的總量：1顆 x 3次 x 3天
      customDosage: false,
      customFrequency: false,
      customDuration: false
    };

    onChange?.([...medications, newMedication]);
    setManualInput('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddManualMedication();
    }
  };

  const calculateTotal = (medication) => {
    try {
      const dosageMatch = medication.dosage.match(/[\d.]+/);
      const frequencyMatch = medication.frequency.match(/[\d.]+/);
      const durationMatch = medication.duration.match(/[\d.]+/);
      
      if (dosageMatch && frequencyMatch && durationMatch) {
        const total = parseFloat(dosageMatch[0]) * 
                     parseFloat(frequencyMatch[0]) * 
                     parseFloat(durationMatch[0]);
        return total.toString();
      }
    } catch (error) {
      console.error('Error calculating total:', error);
    }
    return '待定';
  };

  const updateMedication = (index, field, value, isCustom = false) => {
    const updated = [...medications];
    const updatedMedication = {
      ...updated[index],
      [field]: value,
      [`custom${field.charAt(0).toUpperCase() + field.slice(1)}`]: isCustom
    };
    
    updatedMedication.total = calculateTotal(updatedMedication);
    updated[index] = updatedMedication;
    onChange?.(updated);
  };

  const removeMedication = (index) => {
    onChange?.(medications.filter((_, i) => i !== index));
  };

  const renderInputField = (medication, index, field, options, placeholder) => {
    const isCustom = medication[`custom${field.charAt(0).toUpperCase() + field.slice(1)}`];
    const fieldValue = medication[field];

    return (
      <div className="relative">
        {isCustom ? (
          <>
            <input
              type="text"
              value={fieldValue}
              onChange={(e) => updateMedication(index, field, e.target.value, true)}
              placeholder={placeholder}
              className="w-full h-8 px-2 text-sm border border-gray-300 rounded"
            />
            <button
              type="button"
              onClick={() => updateMedication(index, field, options[0], false)}
              title="切換到選單模式"
              className="absolute right-1 top-1/2 transform -translate-y-1/2 text-xs text-gray-500 hover:text-gray-700"
            >
              ⇄
            </button>
          </>
        ) : (
          <>
            <select
              value={fieldValue}
              onChange={(e) => updateMedication(index, field, e.target.value, false)}
              className="w-full h-8 px-2 text-sm border border-gray-300 rounded appearance-none"
            >
              <option value="">請選擇</option>
              {options.map((option, i) => (
                <option key={i} value={option}>{option}</option>
              ))}
            </select>
            <button
              type="button"
              onClick={() => updateMedication(index, field, '', true)}
              title="切換到手動輸入"
              className="absolute right-1 top-1/2 transform -translate-y-1/2 text-xs text-gray-500 hover:text-gray-700"
            >
              ⇄
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-2">
      {/* Medicine name input */}
      <div className="flex items-center gap-2">
        <input
          type="text"
          value={manualInput}
          onChange={handleManualInputChange}
          onKeyPress={handleKeyPress}
          placeholder="輸入藥品名稱後按 Enter"
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
        <button
          onClick={handleAddManualMedication}
          type="button"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          +
        </button>
      </div>

      {/* Medications list */}
      <div className="space-y-1">
        {medications.map((med, index) => (
          <div key={med.id} className="flex items-center gap-2 p-2 bg-white border border-gray-200 rounded-lg">
            {/* Medicine name */}
            <div className="w-48 truncate font-medium text-sm">
              {med.name}
            </div>

            {/* Medicine details in a compact grid */}
            <div className="flex-1 grid grid-cols-4 gap-2">
              <div>
                {renderInputField(med, index, 'dosage', dosageOptions, '1')}
              </div>
              <div>
                {renderInputField(med, index, 'frequency', frequencyOptions, '1')}
              </div>
              <div>
                {renderInputField(med, index, 'duration', durationOptions, '1')}
              </div>
              <div>
                <input
                  type="text"
                  value={med.total}
                  readOnly
                  className="w-full h-8 px-2 text-sm border border-gray-300 rounded bg-gray-50 text-gray-600"
                />
              </div>
            </div>

            {/* Delete button */}
            <button
              onClick={() => removeMedication(index)}
              type="button"
              className="p-1 text-red-500 hover:text-red-700"
            >
              ✕
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicineSearch;