import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, query, collection, where, getDocs, setDoc, writeBatch } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import Layout from '../../Layout';
import useUserStore from '../../stores/useUserStore';
import DoctorSelector from '../../components/DoctorSelector';
import PatientList from '../../components/PatientList';
import ConsultationForm from '../../components/ConsultationForm';
import HistoryAndChat from '../../components/HistoryAndChat';
import { db } from '../../components/Firebase';
import MedicalHistoryDetailModal from '../../components/Modals/MedicalHistoryDetailModal';
import ICDCodesUploader from '../../components/ICDCodesUploader';

function DoctorConsultation() {
  const { doctorId } = useParams();
  const formRef = useRef(null);
  const navigate = useNavigate();

  // State management
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [viewingHistoryRecord, setViewingHistoryRecord] = useState(null);
  const [unsavedRecords, setUnsavedRecords] = useState({});
  const [isShowingHistoryModal, setIsShowingHistoryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { user, targetClinicId, loading: userLoading } = useUserStore();

  // Load clinic doctors and setup initial doctor
  useEffect(() => {
    const fetchDoctors = async () => {
      if (!targetClinicId) return;

      try {
        const clinicRef = doc(db, 'OwnedClinic', targetClinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctorsList = clinicData.doctors || [];
          setDoctors(doctorsList);

          // Handle doctor selection from URL
          const urlParams = new URLSearchParams(window.location.search);
          const urlDoctorId = urlParams.get('doctorId');

          if (urlDoctorId) {
            const doctor = doctorsList.find(d => d.doctorId === urlDoctorId);
            if (doctor) {
              setSelectedDoctor(doctor);
            }
          } else if (doctorsList.length > 0) {
            setSelectedDoctor(doctorsList[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
        toast.error('無法載入診所醫師資料');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDoctors();
  }, [targetClinicId]);

  useEffect(() => {
    const loadCurrentRecord = async () => {
      if (!selectedPatient || !targetClinicId) return;

      try {
        // Check for unsaved record first
        if (unsavedRecords[selectedPatient.id]) {
          setCurrentRecord(unsavedRecords[selectedPatient.id]);
          return;
        }

        // Look for existing in-progress record
        const recordsQuery = query(
          collection(db, 'MedicalRecords'),
          where('patientId', '==', selectedPatient.id),
          where('clinicId', '==', targetClinicId),
          where('status', '==', '進行中')
        );

        const querySnapshot = await getDocs(recordsQuery);
        if (!querySnapshot.empty) {
          const recordData = querySnapshot.docs[0].data();
          setCurrentRecord({ id: querySnapshot.docs[0].id, ...recordData });
        } else {
          // Create new empty record structure
          const newRecordId = `${selectedPatient.id}_${Date.now()}`;
          setCurrentRecord({
            id: newRecordId,
            patientId: selectedPatient.id,
            patientName: selectedPatient.userName || '未知',
            complains: '',
            diagnosis: '',
            medications: [],
            imageUrls: [],
            diagnosisCodes: [],
            status: '進行中'
          });
        }
      } catch (error) {
        console.error('Error loading current record:', error);
        toast.error('無法載入病歷資料');
      }
    };

    loadCurrentRecord();
  }, [selectedPatient, targetClinicId, unsavedRecords]);

  // Handle patient selection and form state
  const handlePatientSelect = async (patient) => {
    if (!patient) return;

    // Check if form has unsaved changes
    if (formRef.current?.isFormDirty()) {
      const shouldSwitch = await formRef.current.handlePatientSwitch();
      if (!shouldSwitch) {
        return;
      }
    }

    // Save current record state to unsavedRecords if needed
    if (currentRecord && formRef.current?.isFormDirty()) {
      setUnsavedRecords(prev => ({
        ...prev,
        [selectedPatient.id]: currentRecord
      }));
    }

    // Update selected patient
    setSelectedPatient(patient);

    // Load unsaved record if exists
    if (unsavedRecords[patient.id]) {
      setCurrentRecord(unsavedRecords[patient.id]);
    } else {
      setCurrentRecord(null);
    }
  };

  // Handle viewing history record
  const handleViewHistoryRecord = (record) => {
    setViewingHistoryRecord(record);
    setIsShowingHistoryModal(true);
  };

  const handleCopyFromHistory = (record) => {
    console.log(record)
    // Create new record with the necessary fields
    const newRecord = {
      ...currentRecord, // Preserve current record ID and other metadata
      complains: record.complains || '',
      diagnosis: record.diagnosis || '',
      diagnosisCodes: record.diagnosisCodes || [],
      diagnosisDetails: record.diagnosisDetails || {},
      medications: record.medications || [],
      imageUrls: record.imageUrls || [],
      temperature: record.temperature,
      respirationRate: record.respirationRate,
      bloodPressure: record.bloodPressure
    };

    // Update the current record state
    setCurrentRecord(newRecord);

    // Use the formRef to update the form data directly
    if (formRef.current) {
      formRef.current.updateFormData({
        complains: record.complains,
        diagnosis: record.diagnosis,
        diagnosisCodes: record.diagnosisCodes,
        diagnosisDetails: record.diagnosisDetails,
        medications: record.medications,
        imageUrls: record.imageUrls,
        temperature: record.temperature,
        respirationRate: record.respirationRate,
        bloodPressure: record.bloodPressure
      });
    }

    // Close the modal and show success message
    setIsShowingHistoryModal(false);
    toast.success('已複製歷史病歷內容');
  };

  // Handle record updates
  const handleRecordUpdate = (updatedRecord) => {
    setCurrentRecord(updatedRecord);
    setUnsavedRecords(prev => ({
      ...prev,
      [selectedPatient.id]: updatedRecord
    }));
  };

  // Loading state
  if (isLoading || userLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main"></div>
        </div>
      </Layout>
    );
  }

  // Show warning if no clinic selected
  if (!targetClinicId) {
    return (
      <Layout>
        <div className="text-center p-4">
          <p className="text-red-500">請先選擇診所</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold mb-6">醫師門診</h1>

        {/* Main content grid */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
          {/* Left sidebar - Doctor selection & Patient list */}
          <div className="lg:col-span-2 bg-white rounded-xl border border-gray-200 p-4">
            <DoctorSelector
              doctors={doctors}
              selectedDoctor={selectedDoctor}
              onDoctorSelect={setSelectedDoctor}
            />
            <PatientList
              selectedDoctor={selectedDoctor}
              targetClinicId={targetClinicId}
              onPatientSelect={handlePatientSelect}
              selectedPatientId={selectedPatient?.id}
            />
          </div>

          {/* Main consultation form */}
          <div className="lg:col-span-7 bg-white rounded-xl border border-gray-200 p-4">
            <ConsultationForm
              ref={formRef}
              selectedPatient={selectedPatient}
              selectedDoctor={selectedDoctor}
              currentRecord={currentRecord}
              onRecordUpdate={handleRecordUpdate}
              targetClinicId={targetClinicId}
            />
          </div>

          {/* Right sidebar - History & Chat */}
          <div className="lg:col-span-3 bg-white rounded-xl border border-gray-200 p-4">
            <HistoryAndChat
              selectedPatient={selectedPatient}
              onViewRecord={handleViewHistoryRecord}
              onCopyRecord={handleCopyFromHistory}
            />
          </div>
        </div>

        {/* Medical History Detail Modal */}
        {isShowingHistoryModal && viewingHistoryRecord && (
          <MedicalHistoryDetailModal
            isOpen={isShowingHistoryModal}
            onClose={() => setIsShowingHistoryModal(false)}
            record={viewingHistoryRecord}
            onCopy={handleCopyFromHistory}
          />
        )}
      </div>
    </Layout>
  );
}

export default DoctorConsultation;